import { Button, Loader } from "components/atoms"
import { HomeLayout } from "components/pages/marketPlace/common/HomeLayout"
import {  useAppViewById, useGetAllApps } from "framework/api/methods"
import headerImage from 'assets/marketPlace/headerImage.png'
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import CN from 'classnames'

export const Home= () => {
  const navigate = useNavigate()
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const [appList, setAppList] = useState([])
  const onSuccessGetAllApps =(data: any)=>{
    setAppList(data?.data)
  }

  const onErrorGetAllApps =({ response: { data: errData }, response }: any)=>{
    if(response?.status===405 || response?.status===403 || response?.status===501 ){
      if(response?.status===403 ){
        notify('Please login to continue.', 'error')
      }else{
        notify('Session expired.', 'error')
      }
      setTimeout(()=>{
        navigate('/auth/login')
        localStorage.clear()
      }, 2000);
    }else{
      notify(errData?.message || 'Something went wrong.', 'error')
    }
  }

  const params = new URLSearchParams(window.location.search);
    const search = params.get('search');

  /** get all app list */
  const { refetch:getAllApps , isRefetching , isLoading } = useGetAllApps({search:search || '' },onSuccessGetAllApps, onErrorGetAllApps)

 useEffect(() => {
   getAllApps()
 }, [search])

 
 const { mutate: appViewsByIdMutate } = useAppViewById()

 // add app view
 const appViewsById = (appId: string) => {
  appViewsByIdMutate({
    appId
   }, {
     onError: ({ response: { data: errData }, response }: any) => {
       if(response?.status===405 || response?.status===403 || response?.status===501 ){
        if(response?.status===403 ){
          notify('Please login to continue.', 'error')
        }else{
          notify('Session expired.', 'error')
        }
         setTimeout(()=>{
           navigate('/auth/login')
           localStorage.clear()
         }, 2000);
       }
     },
   })
 }

 sessionStorage.clear()

  return (
    <HomeLayout>
      <div className="md:hidden flex">
        <img src={headerImage} 
            alt='header-img' 
            className='"!md:hidden lg:hidden flex text-4xl font-medium py-5 w-[70vw]'
          />
      </div>
      {(isRefetching || isLoading) && <Loader  className="mt-10"/>}

      <div className="mt-2 md:mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-3 gap-y-5 md:gap-x-6 lg:gap-x-8 overflow-y-auto styled-scroll">
       {!(isRefetching || isLoading) && appList?.length>0 && appList?.map((item: any, index)=> 
        <div key={index} className={CN("flex flex-col w-full bg-[#292A2C] rounded-lg px-4 py-5 justify-between", {
          'opacity-40 cursor-not-allowed': item.status === "false"
        })}>
            <div className="flex w-full justify-between items-start">
              <div className="flex flex-col">
                <div className="headings-font text-base">{item.app_name}</div>
                <div className="pt-1 captions-font text-xs">{item.vender_name}</div>

                <div className="pt-4 captions-font text-xs">{item.short_description}</div>
              </div>

              <img src={item?.app_icon} alt={item.app_name} className="w-[60px] h-[60px] rounded-full"/>
            </div>
          
            <div className="flex flex-row justify-between items-end pt-6">
              <div className="flex gap-x-2">
                <div className="flex justify-center items-center captions-font  text-xs">{item.average_rating?.toFixed(1)} <i className="ri-star-fill pl-1" /></div>
                <div className="flex justify-center items-center captions-font  text-xs">{item.views_count} <i className="ri-eye-line pl-1" /></div>
              </div>

              <Button
                appearance="market-red" 
                className='w-fit px-6 rounded-md' 
                isBlock
                onClick={()=>{
                  if(item.status==="true")
                  {
                    appViewsById(item.app_id)
                    navigate(`/app-details/${item.app_id}`, { state: { isBack: true }})
                  }
                }}
              >
                Explore
              </Button> 
            </div>
        </div>
        )
       }
      </div>
    </HomeLayout>
  )
  
}

export default Home
