import { Button, Input } from "components/atoms"
import account from 'assets/marketPlace/account.svg'
import wallet from 'assets/marketPlace/wallet.svg'
import Info from 'assets/marketPlace/help.png'
import headerImage from 'assets/marketPlace/headerImage.png'
import { useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ProfileDrawer, WalletDrawer } from "components/molecules/Drawers/MarketPlace"
import { InfoModal } from "components/molecules/InfoModal"

export const HomeLayout= ({children}: any) => {

  const navigate = useNavigate()
  const params = new URLSearchParams(window.location.search);
  const search = params.get('search');
  
  const token = localStorage.getItem('token')
  const [inputValue, setInputValue] = useState(search);

  


  const [isOpenProfileDrawer, setIsOpenProfileDrawer] = useState(false)
  const [isOpenWalletDrawer, setIsOpenWalletDrawer] = useState(false)
  const [isShowInfoModal,setIsShowInfoModal] = useState(false)

  const debounce = (func: any, delay: any) => {
    let timer: any;
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  // Update URL parameter function
  const updateUrlParameter = (param: any, value: any) => {
    const url = new URL(window.location.href);
    if (value) {
      url.searchParams.set(param, value);
    } else {
      url.searchParams.delete(param);
    }
    navigate(`${url.pathname}?${url.searchParams.toString()}`, { replace: true });
  };
 
   // Debounced version of updateUrlParameter
   const debouncedUpdateUrlParameter = useCallback(debounce(updateUrlParameter, 500), []);

   

  return (
    <div className="flex flex-col bg-black h-full w-full py-4 px-6 md:px-14 md:py-12 text-white">     
      <div className="flex justify-between items-center">
        <img src={headerImage} 
          alt='header-img' 
          className='hidden md:flex w-[35vw]'
        />

        <div className="flex items-center justify-between md:justify-end w-full">
          <Input labelValue="" value={inputValue} placeholder="Search apps & services" onChange={(e)=>{
                setInputValue(e.target.value)
                debouncedUpdateUrlParameter('search', e.target.value);
          }} 
          className="w-[90%] md:w-[280px]"
          inputClassName="!rounded-[30px] h-[45px]"
          icon={'ri-search-line'}
          />
          
          
            
           {/* header icons - mobile only */}
           {token && 
            <div className="md:hidden flex pr-5">
              <img src={Info} 
                alt='Info' 
                className='cursor-pointer w-[26px] h-[26px] object-contain ml-[16px]'
                onClick={()=>{
                  setIsShowInfoModal(true)
                }}
              />
              <img src={wallet} 
                alt='wallet' 
                className='cursor-pointer w-[26px] h-[26px] object-contain ml-[12px]'
                onClick={()=>{navigate('/my-wallet')}}
              />
              <img src={account} 
                alt='account' 
                className='cursor-pointer w-[26px] h-[26px] object-contain ml-[12px]'
                onClick={()=>{navigate('/my-profile')}}
              />
            </div>
          }

          {/* header icons - tab and desktop only */}
          {token && 
          <div className="hidden md:flex">
            <img src={Info} 
                alt='Info' 
                className='cursor-pointer w-[26px] h-[26px] object-contain ml-[16px]'
                onClick={()=>{
                  setIsShowInfoModal(true)
                }}
              />

            <img src={wallet} 
              alt='wallet' 
              className='cursor-pointer w-[26px] h-[26px] object-contain ml-[16px]'
              onClick={()=>{setIsOpenWalletDrawer(true)}}
            />
            <img src={account} 
              alt='account' 
              className='cursor-pointer w-[26px] h-[26px] object-contain ml-[16px]' 
              onClick={()=>{setIsOpenProfileDrawer(true)}}
            />
          </div>}
          
          {!token && 
          <div className=" pl-2 flex justify-center items-center">
            <Button
              appearance="market-red" 
              className='w-[100px] ml-2' 
              onClick={()=>{
                navigate('/auth/login')
              }}
            >
              Login
            </Button>

            <img src={Info} 
                alt='Info' 
                className='cursor-pointer w-[26px] h-[26px] object-contain ml-[4px] md:ml-3'
                onClick={()=>{
                  setIsShowInfoModal(true)
                }}
              />
          </div>
          }
        </div>
      </div>

      {children && children}
     
     <WalletDrawer isOpen={isOpenWalletDrawer} setIsOpen={setIsOpenWalletDrawer} />

     <ProfileDrawer isOpen={isOpenProfileDrawer} setIsOpen={setIsOpenProfileDrawer} />

     <InfoModal isOpen={isShowInfoModal} setIsOpen={setIsShowInfoModal} />
    </div>
  )
  
}

export default HomeLayout
