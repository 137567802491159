import { useQuery } from "react-query";
import { get } from "framework/api/http";

/* Get all apps */
export const getAllApps = async ({queryKey}: any) => {
    const requestBody = queryKey[1]
    const res = await get(`/app-api/search_app_name?appName=${requestBody.search|| ''}`)
    return res?.data || []
  }

/* Use Query as a Hook */
export const useGetAllApps = ({...reqBody}, onSuccess: any, onError: any ) => {
    return useQuery(['all-apps',{...reqBody}], getAllApps, {
      refetchOnWindowFocus: false,
      enabled: false,
      retry:0,
      onSuccess,
      onError,
    })
  }

export default useGetAllApps;


