import { useLocation, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { useEffect, useRef, useState } from "react";
import { Button, Loader } from "components/atoms";
import { useAddDownloads, useGetAppById, useGetTemplateByIdAndAppId } from "framework/api/methods";
import html2canvas from 'html2canvas'
import wallet from 'assets/marketPlace/wallet-balck.png'
import { Modal } from "components/molecules";
import { WalletDrawer } from "components/molecules/Drawers";

export const PersonalizePage= () => {
  const navigate = useNavigate()
 const params = useParams()
 const location = useLocation()

  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }


 useEffect(() => {
  if(sessionStorage.getItem('category_1')===null){
    navigate(`/designer/${params.id}/product`)
  }
 }, [sessionStorage])


 const token = localStorage.getItem('token')
 
 const containerRef:any = useRef(null)


 const [selectedTemplateData, setSelectedTemplateData] = useState<any>(null)
 const [updatedImage, setUpdatedImage] = useState<any>(null)
 const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
 const [isOpenWalletDrawer, setIsOpenWalletDrawer] = useState(false)

 const { mutate: getTemplateByIdAndAppIdMutate, isLoading: getTemplateByIdAndAppIdIsLoading } = useGetTemplateByIdAndAppId()

 const getTemplateByIdAndAppId = () => {
  getTemplateByIdAndAppIdMutate({
    appId: params.id?.toString() || '',
    templateId: params.templateId?.toString() || '',
   }, {
     onSuccess: ({ data: successData }: any) => {
        setSelectedTemplateData(successData?.data[0])
        location?.state?.AIImage ? setUpdatedImage(location?.state?.AIImage) : setUpdatedImage(successData?.data[0].imageURL)
     },
     onError: ({ response: { data: errData }, response }: any) => {
       if(response?.status===405 || response?.status===403 || response?.status===501 ){
        if(response?.status===403 ){
          notify('Please login to continue.', 'error')
        }else{
          notify('Session expired.', 'error')
        }
         setTimeout(()=>{
           navigate('/auth/login')
           localStorage.clear()
         }, 2000);
       }else{
         notify(errData?.message || 'Something went wrong.', 'error')
       }
       
     },
   })
 }

 useEffect(() => {
  if(params.id && params.templateId){
    getTemplateByIdAndAppId()
  }
 }, [params])

 const downloadImage = async (imageUrl: string) => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    
    const a = document.createElement('a');
    a.href = url;
    a.download = 'download_image.jpg'; // Set the file name
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

 
  const { mutate: addDownloadsMutate, isLoading: addDownloadsIsLoading } = useAddDownloads()

 // get add downloads
 const addDownloads = () => {
  addDownloadsMutate({
    appId:params.id?.toString() || '' ,
    imageURL: updatedImage || '',
    type: "free"
   }, {
     onSuccess: ({ data: successData }: any) => {
      downloadImage(updatedImage)
     },
     onError: ({ response: { data: errData }, response }: any) => {
       if(response?.status===405 || response?.status===403 || response?.status===501 ){
        if(response?.status===403 ){
          notify('Please login to continue.', 'error')
        }else{
          notify('Session expired.', 'error')
        }
         setTimeout(()=>{
           navigate('/auth/login')
           localStorage.clear()
         }, 2000);
       }else{
         notify(errData?.message || 'Something went wrong.', 'error')
       }
     },
   })
 }

 useEffect(() => {
  fetch(updatedImage)
    .then(response => response.blob())
    .then(blob => {
      const url: any = URL.createObjectURL(blob);
      document.querySelectorAll("img[id='preview-img']").forEach((img: any) => {
        img.src = url;
      });
    })
}, []); 

const dataURLtoBlob=(dataURL: any) => {
  const byteString = atob(dataURL.split(',')[1]);
  const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
}

 const captureAndShare = async () => {
  try {
    const canvas = await html2canvas(containerRef?.current);
    const imageData = canvas?.toDataURL("image/png");
    const blob = dataURLtoBlob(imageData);
    const file = new File([blob], "image.jpg", { type: "image/jpeg" });
    await navigator.share({ files: [file] } as ShareData);
  } catch (error: any) {
    if (error?.name === "NotAllowedError") {
      // Handle the case where user denied permission (optional)
      html2canvas(containerRef.current)?.then((canvas) => {
        const dataURL = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.download = `design-${new Date().getTime()}.png`;
        const blob = dataURLtoBlob(dataURL);
        link.href = window.URL.createObjectURL(blob);
        link.click();
      });
    } else if (error?.name === "AbortError") {
      console.error(error);
    } else {
      console.error(error);
    }
  }
};

useEffect(() => {
  fetch(updatedImage)
    .then(response => response.blob())
    .then(blob => {
      const url: any = URL.createObjectURL(blob);
      document.querySelectorAll("img[id='preview-img']").forEach((img: any) => {
        img.src = url;
      });
    })
}, [updatedImage]); 


const [appName, setAppName] = useState(sessionStorage.getItem('app-name'))

const { mutate: getAppByIdMutate, isLoading: getAppByIdIsLoading, } = useGetAppById()

const getAppById = () => {
 getAppByIdMutate({
   appId: params.id?.toString() || ''
  }, {
    onSuccess: ({ data: successData }: any) => {
     setAppName(successData?.data[0]?.app_name)
     sessionStorage.setItem('app-name',successData?.data[0]?.app_name || '')
    },
    onError: ({ response: { data: errData }, response }: any) => {
      if(response?.status===405 || response?.status===403 || response?.status===501 ){
       if(response?.status===403 ){
         notify('Please login to continue.', 'error')
       }else{
         notify('Session expired.', 'error')
       }
        setTimeout(()=>{
          navigate('/auth/login')
          localStorage.clear()
        }, 2000);
      }else{
        notify(errData?.message || 'Something went wrong.', 'error')
      }
      
    },
  })
}

useEffect(() => {
 if(params.id){
   getAppById()
 }
}, [params.id])


  return (
    <div className='flex flex-col bg-black h-full w-full py-4 px-6 md:px-32 md:py-12 text-white'>

      <span className="text-[40px] md:text-[42px] lg:text-[48px] hidden md:flex">{appName}</span>

      <div className="bg-black md:bg-[#292A2C] md:mt-5 h-full flex flex-col md:px-14 md:py-6 rounded-lg w-full overflow-y-auto hidescrl">
        <div className="w-full flex justify-between items-start">
          <i 
            className="ri-arrow-left-line text-[24px] pb-5 md:pb-2" 
            onClick={()=>{
              navigate(-1)
            }}
          />

          <span className="text-[24px] md:hidden flex">{appName}</span>
        </div>
        
        <div className="text-[32px] leading-tight captions-medium">
          Personalize
        </div>

        <div className="text-[14px] leading-tight captions-font pt-1 font-light">
          Download, place order or customize the design as you wish
        </div>

        <div className="flex flex-col md:flex-row md:overflow-scroll hidescrl mt-10 gap-x-0 md:gap-x-10 gap-y-6 md:gap-y-0">
          <div className="flex flex-col justify-start items-start w-full h-full md:w-1/3">
            <Button
              appearance='market-red'
              className="w-fit rounded mb-4"
              disabled={!updatedImage}
              isLoading={addDownloadsIsLoading}
              onClick={() => {
                addDownloads()
              }}
              >
              Download
            </Button>

            {getTemplateByIdAndAppIdIsLoading && <Loader  className="mt-5"/>}
            
            {updatedImage &&
            <div  ref={containerRef} id='card-pre' className='flex'>
              <img alt='preview-imageURL' id='preview-img' className='w-full h-auto md:w-auto md:h-[calc(100%-60px)] rounded bg-gray-200'/>
            </div>
            }
          </div>

          <div className="flex w-full md:w-2/3 flex-col justify-start items-center gap-y-3 md:px-10 md:pt-[60px]">
            <Button
              appearance='market-red'
              className="md:max-w-[350px]"
              isBlock
              onClick={() => {
                if(token){
                  navigate(`/designer/${params.id}/request-quote/${params.templateId}`,{state: { updatedImage: updatedImage } });
                }
                else{
                  notify('Please login to continue.', 'error')
                  setTimeout(()=>{
                    navigate('/auth/login')
                  }, 2000);
                }
              }}
              >
              Request Quote
            </Button>

            <Button
              appearance='market-outline'
              className="md:max-w-[350px]"
              isBlock
              onClick={() => {
                if(token){
                  setIsConfirmationModalOpen(true)
                }
                else{
                  notify('Please login to continue.', 'error')
                  setTimeout(()=>{
                    navigate('/auth/login')
                  }, 2000);
                }
                
              }}
              disabled={location?.state?.AIImage}
              >
              Customize with AI
            </Button>

            <Button
              appearance='market-outline'
              className="md:max-w-[350px]"
              isBlock
              // disabled={!token}
              onClick={() => {
                captureAndShare()
              }}
              >
              Share via WhatsApp
            </Button>
          </div>
        </div>
      </div>  

      <Modal
        isActive={isConfirmationModalOpen}
        onOverlayClick={() => {
          setIsConfirmationModalOpen(false)
        }}
        isFooterShow={false}
        modalProps={{
          classNames:'!w-[60%] md:w-[45%]'
        }}
        isHeaderShow={false}
        >
        <div className='flex flex-col justify-center items-center p-6'>
          <span className='text-gray-900 text-[14px] text-center font-medium'>
          Before using the 'Customize with AI' feature, please ensure you top up credits in your wallet.
          <br/>
          <br/>
          <span className='text-xs leading-none'>
          *For each AI customization, 1 Credit will be deducted, and credit conversions via Add to Bill or Star Points cannot be reversed.
         </span>
          </span>
        </div>

        <div className="footer-section w-full p-2 gap-y-2 flex flex-col border-t border-t-neutral-400 justify-center items-center px-10">
          <Button appearance="market-red" className="h-[49.6px]" isBlock onClick={()=>{
            setIsConfirmationModalOpen(false)
            navigate(`/designer/${params.id}/customized/${params.templateId}`,{ state: { promptMessage: selectedTemplateData.prompt } })
          }}>OK</Button>

          <Button appearance="market-outline" isBlock className="hidden md:flex"
          onClick={()=>{
            setIsConfirmationModalOpen(false)
            setIsOpenWalletDrawer(true)
          }}>
          <div className='flex text-center w-full justify-center items-center text-black'>
              Open your wallet
             <img src={wallet} alt='walletImg' className='w-[20px] h-[20px] ml-2'/>
           </div>
          </Button>

          <Button appearance="market-outline" isBlock className="md:hidden flex"
          onClick={()=>{
            setIsConfirmationModalOpen(false)
            navigate('/my-wallet')
          }}>
          <div className='flex text-center w-full justify-center items-center text-black'>
              Go to your wallet
             <img src={wallet} alt='walletImg' className='w-[20px] h-[20px] ml-2'/>
           </div>
          </Button>
        </div>
      </Modal>

      <WalletDrawer isOpen={isOpenWalletDrawer} setIsOpen={setIsOpenWalletDrawer} />
    </div>
  )
  
}

export default PersonalizePage
