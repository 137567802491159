import { Button } from 'components/atoms'
import { FC, useState } from 'react'
import { Modal } from '../Modal'


interface InfoModalProps {
    isOpen: boolean
    setIsOpen: any
  [x: string]: any
}

export const InfoModal: FC<InfoModalProps> = ({
    isOpen,
    setIsOpen,
  ...restProps
}: InfoModalProps) => {
    const [step, setStep] = useState(0);

    const nextStep = () => {
        setStep(step + 1);
    };

    const handleOk = () => {
        closePopup()
      };

    const closePopup = ()=>{
        setStep(0)
        setIsOpen(false)
    }
  
  return (
    <Modal
    isActive={isOpen}
    primaryButtonProps={{
      className:'!w-[50%]',
    }}
    isFooterShow={false}
    onOverlayClick={() => {
        closePopup()
    }}
    className='z-[1000] p-10'
    secondaryButtonTitle=''
    footerButtonAlignment='center'
    isHeaderShow={false}
    primaryButtonTitle=''>
    <div className='flex flex-col justify-start items-center px-8 py-4 min-h-[40vh]'>
    {step === 0 && (
        <div className='flex flex-col flex-1 justify-between h-full'>
          <div className='w-full text-center flex flex-col justify-start items-center h-full flex-1'>
                <div className='mb-6 text-sm text-gray-400 w-full text-center'>Step 1 of 3</div>
                <div className='h-full flex-1 flex justify-center items-center flex-col'>
                    <span className='text-[14px] pb-3 text-gray-800 font-bold'>Welcome to the AI Marketplace!</span>
                    <span className='text-[12px] pb-6 text-gray-600 font-normal'>Discover AI-powered apps that let you create customized designs for cards, cakes, merchandise, and more.
                    </span>
                </div>
          </div>
          <div className='w-full flex justify-center items-center'>
          <Button
            appearance='market-red'
            onClick={() => {
                nextStep()
            }}
           >
            Next
           </Button>
           </div>
        </div>
      )}
      {step === 1 && (
         <div className='flex flex-col flex-1  justify-between h-full'>
          <div className='w-full text-center flex flex-col justify-start items-center  h-full flex-1'>
           <div className='mb-6 text-sm text-gray-400 w-full text-center'>Step 2 of 3</div>
           <div className='h-full flex-1 flex justify-center items-center flex-col'>
            <span className='text-[14px] pb-3 text-gray-800 font-bold'>Browse offerings from top vendors, customize your designs, and request a quote directly.</span>
            <span className='text-[12px] pb-6 text-gray-600 font-normal'>To get started with your unique creation, ensure to top up credits in your wallet via "Add to Bill" or "Star Points".</span>
          </div>
          </div>
          <div className='w-full flex justify-center items-center'>
          <Button
            appearance='market-red'
            onClick={() => {
                nextStep()
            }}
           >
            Next
           </Button>
           </div>
          
        </div>
      )}
      {step === 2 && (
        <div className='flex flex-col flex-1  justify-between h-full'>
          <div className='w-full text-center flex flex-col justify-start flex-1  items-center h-full'>
            <div className='mb-6 text-sm text-gray-400 w-full text-center'>Step 3 of 3</div>
                <div className='h-full flex-1 flex justify-center items-center flex-col'>
                    <span className='text-[14px] pb-3 text-gray-800 font-bold'>You're all set! Start exploring the AI Marketplace to bring your ideas to life. </span>
                    <span className='text-[12px] pb-6 text-gray-600 font-normal'>Have fun creating!
                    </span>
                </div>
        </div>
        <div className='w-full flex justify-center items-center'>
          <Button
            appearance='market-red'
            onClick={() => {
                handleOk()
            }}
            >
            Ok
          </Button>
          </div>
        </div>
      )}
    </div>
  </Modal>
  )
}

export default InfoModal

InfoModal.defaultProps = {
    isOpen: false,
}
