import { Slider } from "components/molecules";
import dialogLogo from 'assets/marketPlace/dialogLogo.png'
import { Button, Input } from "components/atoms";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useRequestOTP } from "framework/api/methods";
import { isEmail } from "utils";
import headerImage from 'assets/marketPlace/headerImage.png'


export const CreateProfile= () => {
  const navigate = useNavigate()
  const location = useLocation();

  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  useEffect(() => {
    setPhoneNumber(location.state?.phoneNumber)
  }, [location])

  const { mutate: requestOTPMutate, isLoading: requestOTPIsLoading } = useRequestOTP()

 // function to request Otp for registered / unregistered users
 const handleRequestOTP=()=> {
   requestOTPMutate({
     mobile: phoneNumber
   }, {
   onSuccess: ({ data: successData }: any) => {
     navigate('/auth/otp-page',{ state: { phoneNumber, name, email, ref:successData?.serverRef, isNewUser: true} })
     notify(successData?.message || 'OTP has been sent successfully', 'success')
   },
   onError: ({ response: { data: errData } }: any) => {
     if(errData?.message){
       notify(errData?.message || 'Something went wrong.', 'error')
     }
   },
 })
}

  return (
    <div className='flex flex-1 h-full min-h-full w-full bg-black text-white px-6 py-10'>
      {/* slider */}
      <div className="hidden md:flex w-1/2 h-full flex-col justify-between md:justify-center items-center pb-0 md:pb-10">
        <div className="flex flex-col w-full justify-center items-center">
          <Slider selectedIndex={2}/>
          <img src={dialogLogo} alt='dialogLogo' className='lg:w-[120px] md:[100px] w-[93px] h-fit mt-[18px] md:mt-[30px] lg:mt-[40px]'/>
          <img src={headerImage} 
          alt='header-img' 
          className='"flex py-5 w-[282px] md:w-[299px] lg:w-[340px] mt-[0px]'
        />

          <Button appearance="market-red" className="mt-9 flex md:hidden"
            onClick={()=>{
              navigate('/auth/login')
            }}
          >Continue with phone number</Button>
        </div>
        
        <Button appearance="link" className="mt-0" 
          onClick={()=>{
            navigate('/term-and-conditions')
          }}
        >T&C | Privacy Notice</Button>
      </div>

      {/* login form */}
      <div className="w-full md:w-1/2 flex-col flex justify-center items-center captions-font">
        <div className="flex flex-col h-full justify-start md:justify-center md:w-fit">
          <i className="ri-arrow-left-line text-[24px] pb-10 md:hidden" onClick={()=>{
              navigate(-1)
            }}/>
          <span className="text-[32px]">Create profile</span>

          <span className="text-[14px] pt-[1px]">Enter the below details to create a profile.</span>

          <Input labelValue="Name" value={name} onChange={(e)=>{
            setName(e.target.value)
          }} className="mt-[120px] md:mt-[60px] w-[320px]"/>

          <Input labelValue="Email" value={email} onChange={(e)=>{
            setEmail(e.target.value)
          }} className="mt-[38px]"/>
          
          <Button 
            appearance="market-red" 
            className="mt-[100px]" 
            isBlock
            isLoading={requestOTPIsLoading}
            disabled={!name || !email || !phoneNumber}
            onClick={()=>{
              
              if(isEmail(email)){
                handleRequestOTP()
              }
              else{
                notify('Invalid email address','error')
              }
            }}
            >Continue</Button>
        </div>
      </div>
    </div>
  );
};

export default CreateProfile
