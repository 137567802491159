import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetAppByIdForAuthInterface {
  appId: string
}

export const useGetAppByIdForAuth = () => {
  const mutate = useMutation(({appId}: GetAppByIdForAuthInterface) => {
    return get(`/app-api/get_app-by_ID_in/${appId}`)
  })

  return mutate
}

export default useGetAppByIdForAuth
